import React, {useContext, useEffect, useState} from 'react'
import {Grid} from '@material-ui/core'
import {getPictures} from './api/picturesApi'
import {useDispatch} from 'react-redux'
import errorCatcher from '../../utils/errorCatcher'
import Loader from '../../components/loader/Loader'
import {INITIAL_ISLANDS_PICTURES, IslandsPicturesModel, PicturesModel} from './types/picturesModel'
import {PREFIX_PICTURES} from '../../utils/fetchFactory'
import {makeStyles, Theme} from '@material-ui/core/styles'
import Lightbox from 'yet-another-react-lightbox'
import 'yet-another-react-lightbox/dist/styles.css'
import 'yet-another-react-lightbox/dist/plugins/thumbnails.css'
import 'yet-another-react-lightbox/dist/plugins/captions.css'
import Thumbnails from 'yet-another-react-lightbox/dist/plugins/Thumbnails'
import Slideshow from 'yet-another-react-lightbox/dist/plugins/Slideshow'
import Fullscreen from 'yet-another-react-lightbox/dist/plugins/Fullscreen'
import Video from 'yet-another-react-lightbox/dist/plugins/Video'
import Captions from 'yet-another-react-lightbox/dist/plugins/Captions'
import {FormattedMessage, useIntl} from 'react-intl'
import {objectGetKeys} from '../../utils/ieCompatibility'
import {islandsEnum} from './enums/islandsEnum'
import {ResponsiveContext} from '../../utils/context/ResponsiveContext'

const useStyles = makeStyles<Theme, { isLargeScreen: boolean }>(
    (theme) => ({
        blocContainer: {
            padding: props => props.isLargeScreen ? '0 30px' : 0
        },
        title: {
            marginBottom: 30,
            fontSize: 18,
            fontWeight: 500
        },
        subtitle: {
            marginBottom: 15,
            fontSize: 15,
            fontWeight: 600
        },
        picturesContainer: {
            '& video': {
                width: '100%',
                height: '100%'
            }
        },
        pictures: {
            width: '100%',
            height: '100%'
        },
        islandsContainer: {
            marginTop: 20
        },
        polynesiaMap: {
            position: 'relative',

            '& > div': {
                position: 'absolute',
                cursor: 'pointer',
                width: '2.5%',
                height: '4%'
            },
            '& svg': {
                color: 'red'
            }
        },
        tahitiPoint: {
            left: '28.7%',
            top: '39.5%'
        },
        tetiaroaPoint: {
            left: '27%',
            top: '36%'
        },
        boraPoint: {
            left: '23%',
            top: '35%'
        },
        rangiroaPoint: {
            left: '33.5%',
            top: '30.5%'
        },
        tikehauPoint: {
            left: '31.5%',
            top: '30%'
        },
        mooreaPoint: {
            left: '25.5%',
            top: '39.5%'
        }
    })
)

const GET_PICTURES = 'get_pictures'

const Pictures = () => {
    const {isLargeScreen} = useContext(ResponsiveContext)
    const classes = useStyles({isLargeScreen})
    const intl = useIntl()
    const [islandsPictures, setIslandsPictures] = useState<IslandsPicturesModel>(INITIAL_ISLANDS_PICTURES)
    const [pictures, setPictures] = useState<PicturesModel[]>([])
    const [selectedIsland, setSelectedIsland] = useState('')
    const [loading, setLoading] = useState<islandsEnum[]>([])
    const dispatch = useDispatch()
    const [open, setOpen] = React.useState(false)

    const seePictures = (island: islandsEnum) => {
        // Si on a déjà les informations sur l'île de charger, on les récupère
        if (islandsPictures[island].length > 0) {
            setPictures(islandsPictures[island])
            setSelectedIsland(island)
            setOpen(true)
        }
        // Sinon, on exécute un call API pour récupérer les photos
        else {
            setLoading((prevState) => [...prevState, island])

            getPictures(island)
                .then((response: any) => {
                    // On enregistre la réponse pour éviter de refaire la requête si on l'a déjà faite
                    setIslandsPictures((prevState) => ({
                        ...prevState,
                        [island]: response
                    }))

                    // On définit les photos à afficher dans la visionneuse de photos
                    setPictures(response)
                    setSelectedIsland(island)

                    // On affiche la visionneuse
                    setOpen(true)
                })
                .catch((error) => dispatch(errorCatcher(error, GET_PICTURES)))
                .finally(() => setLoading((prevState) => prevState.filter((prevLoading) => prevLoading !== island)))
        }
    }

    const elementIsVideo = (picture: PicturesModel) => {
        const splittedName = picture.name.split('.')
        const extension = splittedName[splittedName.length - 1]
        const videoExtensions = ['mp4', 'mov']

        return videoExtensions.includes(extension.toLowerCase())
    }

    const videoElements = (pictures: PicturesModel[]) => pictures.filter((picture) => elementIsVideo(picture))
    const imageElements = (pictures: PicturesModel[]) => pictures.filter((picture) => !elementIsVideo(picture))

    return (
        <Grid container item xs={12} className={classes.blocContainer}>
            <Grid container item xs={12} className={classes.title}>
                <FormattedMessage
                    id="pictures.title"
                    defaultMessage="Les points sont les îles que l'on a visité {br} Cliquez dessus pour partager avec nous nos souvenirs !"
                    description="Page title"
                    values={{
                        br: <br/>
                    }}
                />
            </Grid>

            <Grid container item xs={12} className={classes.subtitle}>
                <FormattedMessage
                    id="pictures.subtitle"
                    defaultMessage="Nous n'aurions jamais pu faire notre voyage de noces sans vous, merci encore à tous pour votre participation !!"
                    description="Thanks message"
                />
            </Grid>

            <Grid container item xs={12} className={classes.polynesiaMap}>
                <img src="/img/pictures/map_polynesia.png" alt="Polynésie Française" width="100%"/>

                {
                    objectGetKeys(islandsEnum).map(
                        (island) => {
                            const islandClassName = `${island}Point`

                            return (
                                // Marqueur pour l'île concernée
                                <div
                                    className={classes[islandClassName as keyof typeof classes]}
                                    onClick={() => seePictures(islandsEnum[island as keyof typeof islandsEnum])}
                                    key={island}
                                >
                                    {
                                        loading.includes(islandsEnum[island as keyof typeof islandsEnum]) && (
                                            <Loader size={20}/>
                                        )
                                    }
                                </div>
                            )
                        }
                    )
                }
            </Grid>

            <Lightbox
                className={classes.picturesContainer}
                open={open}
                close={() => setOpen(false)}
                slides={
                    imageElements(pictures)
                        .map(
                            (picture) => ({
                                src: `${PREFIX_PICTURES}${picture.url}`,
                                alt: picture.name,
                                title: intl.formatMessage({
                                    id: `pictures.${selectedIsland ?? 'tahiti'}`,
                                    defaultMessage: 'Tahiti',
                                    description: 'Island name'
                                })
                            })
                        )
                        .concat(
                            videoElements(pictures)
                                .map(
                                    (picture) => ({
                                        type: 'video',
                                        width: '100%',
                                        src: '',
                                        alt: '',
                                        title: intl.formatMessage({
                                            id: `pictures.${selectedIsland ?? 'tahiti'}`,
                                            defaultMessage: 'Tahiti',
                                            description: 'Island name'
                                        }),
                                        sources: [
                                            {
                                                type: 'video/mp4',
                                                src: `${PREFIX_PICTURES}${picture.url}`
                                            }
                                        ]
                                    })
                                )
                        )
                }
                // @ts-ignore
                plugins={[Slideshow, Thumbnails, Fullscreen, Video, Captions]}
            />
        </Grid>
    )
}

export default Pictures
