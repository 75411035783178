enum httpResponse {
	unauthorized = 'Unauthorized',
	ok = 'OK'
}

export default httpResponse

export enum httpStatus {
	ok = 200,
	badRequest = 400,
	unauthorized = 401,
	forbidden = 403,
	internal = 500
}
