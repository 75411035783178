import {createMuiTheme} from '@material-ui/core/styles'

const black = '#222831'
const gray = '#393e46'
const green = '#3C613E'
const white = '#eeeeee'

const fontFamilyBodoniFLF = '\'BodoniFLF\', sans-serif'
const fontFamilyAdamScript = '\'AdamScript\', sans-serif'

const theme = createMuiTheme({
	overrides: {
		MuiSnackbarContent: {
			root: {
				backgroundColor: green
			}
		},
		MuiInputBase: {
			root: {
				fontFamily: fontFamilyBodoniFLF
			}
		}
	},
	typography: {
		title0: {
			fontSize: 72,
			fontWeight: 900,
			fontFamily: fontFamilyAdamScript,
			lineHeight: '0.96'
		},
		title1: {
			fontSize: 48,
			fontWeight: 900,
			fontFamily: fontFamilyAdamScript,
			lineHeight: '1.33'
		},
		title2: {
			fontSize: 32,
			fontWeight: 900,
			fontFamily: fontFamilyAdamScript,
			lineHeight: '1.13'
		},
		title3: {
			fontSize: 24,
			fontWeight: 900,
			fontFamily: fontFamilyAdamScript,
			lineHeight: '1.17'
		},
		title4: {
			fontSize: 16,
			fontWeight: 'bold' as 'bold',
			fontFamily: fontFamilyBodoniFLF,
			lineHeight: '1.5'
		},
		title5: {
			fontSize: 14,
			fontWeight: 'bold' as 'bold',
			fontFamily: fontFamilyBodoniFLF,
			lineHeight: '1.43'
		},
		title6: {
			fontSize: 28,
			fontWeight: 900,
			fontFamily: fontFamilyAdamScript,
			lineHeight: '1.21'
		},
		title7: {
			fontSize: 16,
			fontWeight: 900,
			fontFamily: fontFamilyAdamScript,
			lineHeight: '1.38'
		},
		title8: {
			fontSize: 20,
			fontWeight: 'bold' as 'bold',
			fontFamily: fontFamilyBodoniFLF,
			lineHeight: 1
		},
		text0: {
			fontSize: 20,
			fontWeight: 600,
			fontFamily: fontFamilyBodoniFLF,
			lineHeight: '1.4'
		},
		text0_opacity: {
			fontSize: 20,
			fontWeight: 600,
			fontFamily: fontFamilyBodoniFLF,
			lineHeight: '1.4',
			opacity: 0.6
		},
		text1_opacity: {
			fontSize: 16,
			fontWeight: 500,
			fontFamily: fontFamilyBodoniFLF,
			lineHeight: '1.5',
			opacity: 0.6
		},
		text1: {
			fontSize: 16,
			fontWeight: 500,
			fontFamily: fontFamilyBodoniFLF,
			lineHeight: '1.5'
		},
		text2_opacity: {
			fontSize: 14,
			fontWeight: 500,
			fontFamily: fontFamilyBodoniFLF,
			lineHeight: '1.57',
			opacity: 0.6
		},
		text2: {
			fontSize: 14,
			fontWeight: 500,
			fontFamily: fontFamilyBodoniFLF,
			lineHeight: '1.57'
		},
		text3_opacity: {
			fontSize: 12,
			fontWeight: 500,
			fontFamily: fontFamilyBodoniFLF,
			lineHeight: '1.67',
			opacity: 0.6
		},
		text3: {
			fontSize: 12,
			fontWeight: 500,
			fontFamily: fontFamilyBodoniFLF,
			lineHeight: '1.67'
		},
		text4_opacity: {
			fontSize: 12,
			fontWeight: 600,
			fontFamily: fontFamilyBodoniFLF,
			lineHeight: '1.67',
			opacity: 0.6
		},
		text4: {
			fontSize: 12,
			fontWeight: 600,
			fontFamily: fontFamilyBodoniFLF,
			lineHeight: '1.67'
		},
		text5: {
			fontSize: 12,
			fontWeight: 'bold' as 'bold',
			fontFamily: fontFamilyBodoniFLF,
			lineHeight: 1,
			letterSpacing: '1.2px'
		},
		text6: {
			fontSize: 11,
			fontWeight: 500,
			fontFamily: fontFamilyBodoniFLF,
			lineHeight: 1
		},
		text6_opacity: {
			fontSize: 11,
			fontWeight: 500,
			fontFamily: fontFamilyBodoniFLF,
			lineHeight: 1,
			opacity: 0.7
		},
		text7: {
			fontSize: 16,
			fontWeight: 600,
			fontFamily: fontFamilyBodoniFLF,
			lineHeight: '1.25'
		},
		text8: {
			fontSize: 14,
			fontWeight: 600,
			fontFamily: fontFamilyBodoniFLF,
			lineHeight: '1.43'
		},
		text9: {
			fontSize: 10,
			fontWeight: 500,
			fontFamily: fontFamilyBodoniFLF,
			lineHeight: '1.6'
		},
		text9_opacity: {
			fontSize: 10,
			fontWeight: 500,
			fontFamily: fontFamilyBodoniFLF,
			lineHeight: '1.6',
			opacity: 0.6
		},
		text10: {
			fontSize: 24,
			fontWeight: 'bold' as 'bold',
			fontFamily: fontFamilyBodoniFLF,
			lineHeight: '1'
		},
		text11: {
			fontSize: 20,
			fontWeight: 'bold' as 'bold',
			fontFamily: fontFamilyBodoniFLF,
			lineHeight: '1.2'
		},
		text11_opacity: {
			fontSize: 20,
			fontWeight: 'bold' as 'bold',
			fontFamily: fontFamilyBodoniFLF,
			lineHeight: '1.2',
			opacity: 0.6
		},
		text12: {
			fontSize: 20,
			fontWeight: 'normal' as 'normal',
			fontFamily: fontFamilyBodoniFLF,
			lineHeight: '1'
		},
		text13: {
			fontSize: 20,
			fontWeight: 500,
			fontFamily: fontFamilyBodoniFLF,
			lineHeight: '1.4'
		},
		text13_opacity: {
			fontSize: 20,
			fontWeight: 500,
			fontFamily: fontFamilyBodoniFLF,
			lineHeight: '1.4',
			opacity: 0.6
		},
		text14: {
			fontSize: 10,
			fontWeight: 600,
			fontFamily: fontFamilyBodoniFLF,
			lineHeight: 'normal'
		},
		text15: {
			fontSize: 14,
			fontWeight: 'normal' as 'normal',
			fontFamily: fontFamilyBodoniFLF,
			lineHeight: 'normal'
		},
		text16_opacity: {
			fontSize: 8,
			fontWeight: 500,
			fontFamily: fontFamilyBodoniFLF,
			lineHeight: 'normal',
			opacity: 0.6
		},
		text17: {
			fontSize: 13,
			fontWeight: 500,
			fontFamily: fontFamilyBodoniFLF,
			lineHeight: '1.54'
		}
	},
	palette: {
		primary: {
			main: black
		},
		secondary: {
			main: gray
		},
		black: {
			main: black
		},
		gray: {
			main: gray
		},
		green: {
			main: green
		},
		white: {
			main: white
		}
	}
})

export default theme
export type ThemeType = typeof theme
