import React, { useContext, useEffect, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { makeStyles } from '@material-ui/core/styles'
import { ClassValue } from 'classnames/types'
import classNames from 'classnames'
import ParticipationForm from './ParticipationForm'
import fetchFactory from '../../../utils/fetchFactory'
import { ParticipantsModel } from '../types/participantsModel'
import { useDispatch } from 'react-redux'
import { displaySnackbar } from '../../../components/snackBar/reducer/actions'
import Loader from '../../../components/loader/Loader'
import links from '../../../enums/linksEnum'
import { useHistory } from 'react-router'
import { UNAUTHORIZED_RESPONSE } from '../utils/commonConst'
import { ResponsiveContext } from '../../../utils/context/ResponsiveContext'
import { AppContext } from '../../../utils/context/AppContext'

const useStyles = makeStyles(
	() => (
		{
			accordionSummaryStyle: {
				alignItems: 'center'
			},
			groupContainer: {
				margin: 0
			}
		}
	)
)

const ERROR_PARTICIPANTS = 'error_participants'

const Participation = () => {
	const { isLargeScreen } = useContext(ResponsiveContext)
	const { setGuests } = useContext(AppContext)
	const classes = useStyles()
	const intl = useIntl()
	const history = useHistory()
	const dispatch = useDispatch()

	const [participants, setParticipants] = useState<ParticipantsModel[]>([])
	const [loading, setLoading] = useState<boolean>(true)

	const accordionSummaryClass: ClassValue = classNames(
		'row',
		classes.accordionSummaryStyle
	)

	useEffect(
		() => {
			fetchFactory('/api/participants')
				.then(
					(participants: any) => {
						setParticipants(participants)
						setGuests(participants)
					}
				)
				.catch(
					(err: any) => {
						dispatch(
							displaySnackbar(
								{
									id: ERROR_PARTICIPANTS,
									message: intl.formatMessage(
										{
											id: err,
											defaultMessage: 'Vous n\'êtes pas autorisé à accéder à ce contenu',
											description: 'Error message'
										}
									),
									open: true,
									hideIcon: true
								}
							)
						)

						if (err === UNAUTHORIZED_RESPONSE) {
							history.push(links.login)
						}
					}
				)
				.finally(
					() => setLoading(false)
				)
		}, [dispatch, intl, history, setGuests]
	)

	if (loading) {
		return (
			<Loader />
		)
	}

	if (participants.length === 0) {
		return (<div></div>)
	}

	return (
		<>
			<h1>
				<FormattedMessage
					id="participation.title"
					defaultMessage="Seriez-vous parmi nous ?"
					description="Participation title"
				/>
			</h1>

			<p>
				<FormattedMessage
					id="participation.message"
					defaultMessage="Nous avons essayé de grouper au maximum les invités par famille, afin qu'une personne puisse répondre pour le groupe dans lequel nous l'avons associé ! Si vous constatez une erreur, n'hésitez pas à contacter Mathias par mail"
					description="Participation message"
					values={
						{
							a: (...chunks: string[]) => <a href="mailto:mja.arredondo@gmail.com">{chunks}</a>
						}
					}
				/>
			</p>

			{
				isLargeScreen ? (
					<Accordion defaultExpanded>
						<AccordionSummary
							expandIcon={<ExpandMoreIcon />}
							aria-controls="panel1c-content"
							id="panel1c-header"
							className={accordionSummaryClass}
						>
							<div className="col-xs-4">
								<h1>
									<FormattedMessage
										id="participation.group"
										defaultMessage="Groupe"
										description="Group's text"
									/>
								</h1>
							</div>
							<div className="col-xs-4">
								<p className={classes.groupContainer}>{participants[0].in_group}</p>
							</div>
						</AccordionSummary>

						<ParticipationForm guests={participants} />
					</Accordion>
				) : (
					<>
						<div className={accordionSummaryClass}>
							<div className="col-xs-4">
								<h1>
									<FormattedMessage
										id="participation.group"
										defaultMessage="Groupe"
										description="Group's text"
									/>
								</h1>
							</div>
							<div className="col-xs-4">
								<p>{participants[0].in_group}</p>
							</div>
						</div>

						<ParticipationForm guests={participants} />
					</>
				)
			}
		</>
	)
}

export default Participation
