import React, { ReactChild, useLayoutEffect, useState } from 'react'
import { IntlProvider } from 'react-intl'
import messages_fr from '../../translations/fr'
import messages_en from '../../translations/en'
import { ModelMessagesList } from './types/languagesModel'
import languages from './enum/languagesEnum'
import localStorageEnum from '../../enums/localStorageEnum'

const messagesList: ModelMessagesList = {
    'fr': messages_fr,
    'en': messages_en
}

const frenchLanguage: string = languages.fr
const englishLanguage: string = languages.en

const allowedLanguages: string[] = [frenchLanguage, englishLanguage]

export const LangContext = React.createContext({
        lang: frenchLanguage, // for currently used language
        switchLang: (ln: string) => {
        }
    }
)

export default (props: { children: ReactChild }) => {
    const [lang, setLang] = useState(frenchLanguage)
    const [messages, setMessages] = useState(messagesList[lang])

    const switchLang = (ln: string) => {
        if (!allowedLanguages.includes(ln)) {
            ln = frenchLanguage
        }

        localStorage.setItem(localStorageEnum.language, ln)
        setLang(ln)
        setMessages(messagesList[ln])
    }

    useLayoutEffect(() => {
        const storedLanguage = localStorage.getItem(localStorageEnum.language) || frenchLanguage
        setLang(storedLanguage)
        setMessages(messagesList[storedLanguage])
    }, [])

    return (
        <LangContext.Provider
            value={{
                lang,
                switchLang
            }}
        >
            <IntlProvider
                locale={lang}
                messages={messages}
            >
                {props.children}
            </IntlProvider>
        </LangContext.Provider>
    )
}
