import {configEnum, ConfigType, responseTypes} from '../enums/fetchFactoryEnum'
import {httpStatus} from '../enums/httpResponseEnum'
import localStorageEnum from '../enums/localStorageEnum'

interface ConfigModel {
    method: ConfigType,
    body?: any,
    headers?: HeadersInit
}

export const PREFIX_PICTURES = 'https://developpements.arredondo-m.ovh/site_mariage/src/resources/pictures/'

const fetchFactory = (
    lien: string,
    config: ConfigModel = {
        method: configEnum.get
    },
    type: responseTypes = responseTypes.json
) => {
    const {
        headers,
        ...others
    } = config
    return new Promise(
        (resolve, reject) => {
            const defaultHeaders: { Authorization: string; userToken: string | null; Accept: string } = {
                Accept: 'application/json',
                Authorization: `Bearer ${localStorage.getItem(localStorageEnum.token)}`,
                userToken: localStorage.getItem(localStorageEnum.token)
            }

            const newConfig = {
                headers: {
                    ...defaultHeaders,
                    ...headers
                },
                ...others
            }

            // @ts-ignore
            fetch(`https://developpements.arredondo-m.ovh/site_mariage/public/index.php${lien}`, newConfig)
            // fetch(lien, newConfig)
                .then(
                    (response: Response) => {
                        if (response.ok) {
                            if (type === responseTypes.json) {
                                response.json()
                                    .then(
                                        (data: any) => {
                                            resolve(data)
                                        }
                                    )
                            }
                            if (type === responseTypes.blob) {
                                response.blob()
                                    .then(blob => {
                                        resolve(blob)
                                    })
                            }
                            if (type === responseTypes.response) {
                                resolve(response)
                            }
                        } else {
                            if (response.status === httpStatus.forbidden || response.status === httpStatus.unauthorized) {
                                reject(response)
                            } else {
                                response.json()
                                    .then(
                                        (data: any) => {
                                            reject(data)
                                        }
                                    )
                                    .catch(
                                        (error: any) => {
                                            reject(error)
                                        }
                                    )
                            }
                        }
                    }
                )
                .catch(
                    (error: any) => {
                        reject(error)
                    }
                )
        }
    )
}

export default fetchFactory
