import React, {useContext} from 'react'
import {useSelector} from 'react-redux'
import {RootState} from '../../index'
import {makeStyles} from '@material-ui/core/styles'
import {ClassValue} from 'classnames/types'
import classNames from 'classnames'
import {ResponsiveContext} from '../../utils/context/ResponsiveContext'
import styles from '../../styles'
import Location from './components/Location'
import Participation from './components/Participation'
import WishList from './components/wishList/WishList'
import {FormattedMessage} from "react-intl";

const useStyles = makeStyles(
    (theme) => (
        {
            dashboardContainerStyle: {
                '& h1': {
                    color: theme.palette.black.main,
                    margin: 0
                },
                '& p': {
                    color: theme.palette.black.main
                }
            },
            dashboardContainerLargeScreenStyle: {
                maxWidth: 1100,
                margin: 'auto',
                marginBottom: 50,

                '& h1': {
                    ...styles(theme).title2
                },
                '& p': {
                    ...styles(theme).text13
                }
            },
            dashboardContainerSmallScreenStyle: {
                margin: 0,
                marginBottom: 25,

                '& h1': {
                    ...styles(theme).title3
                },
                '& p': {
                    ...styles(theme).text1
                }
            },
            userName: {
                textTransform: 'capitalize'
            },
            locationContainerLargeScreenStyle: {
                marginTop: 50,
                marginBottom: 50
            },
            locationContainerSmallScreenStyle: {
                marginTop: 25,
                marginBottom: 25
            }
        }
    )
)

const Dashboard = () => {
    const classes = useStyles()
    const user = useSelector((state: RootState) => state.user)
    const {isLargeScreen} = useContext(ResponsiveContext)

    const dashboardContainerClass: ClassValue = classNames(
        'row',
        classes.dashboardContainerStyle,
        isLargeScreen ? classes.dashboardContainerLargeScreenStyle : classes.dashboardContainerSmallScreenStyle
    )

    const locationContainerClass: ClassValue = classNames(
        'col-xs-12',
        isLargeScreen ? classes.locationContainerLargeScreenStyle : classes.locationContainerSmallScreenStyle
    )

    return (
        <div className={dashboardContainerClass}>
            <div className="col-xs-12">
                <h1>Hello <span className={classes.userName}>{user.firstname}</span> !</h1>

                <p>
                    <FormattedMessage
                        id="dashboard.informationBloc"
                        defaultMessage="Voici quelques informations sur le mariage, n'hésitez pas à revenir de temps en temps nous ajouterons des informations utiles (logements à proximité par ex.) !"
                        description="Informations about the website"
                    />
                </p>
            </div>

            <div className={locationContainerClass}>
                <Location/>
            </div>

            <div className="col-xs-12">
                <Participation/>
            </div>

            <div className="col-xs-12">
                <WishList/>
            </div>
        </div>
    )
}

export default Dashboard
