import flatten from 'flat'

const target = {
	global: {
		errors: {
			required: 'Requis*'
		}
	},
	user: {
		unauthorized: 'Vous n\'êtes pas connecté(e)'
	},
	announce: {
		title: 'Bienvenue sur le site de mariage de Mathilde et Mathias',
		firstText: 'Cet espace vous donne toutes les informations relatives à ce jour si important pour nous.',
		secondText: 'Il nous permet également de savoir si vous serez à nos côtés. Pour y accéder, rien de plus simple : inscrivez votre prénom et votre nom.',
		thirdText: '(Nous comptons sur vous pour ne pas vous connecter sur un espace qui n\'est pas le vôtre)',
		dontShowAgain: 'Ne plus afficher ce message',
		understood: 'Compris !'
	},
	login: {
		firstname: 'Votre prénom',
		lastname: 'Votre nom',
		submit: 'Se connecter',
		errors: {
			credentials: {
				notFound: 'Utilisateur inconnu, vous ne pouvez pas accéder au site',
				empty: 'Veuillez indiquer votre prénom et votre nom'
			}
		},
		smallText: 'Si vous rencontrez des difficultés à vous connecter, veuillez contacter Mathias au 0621600320 ou par <a>mail</a> (mja.arredondo@gmail.com)'
	},
	topAlert: {
		timesUp: 'ON EST MARIÉÉÉÉÉÉÉÉ !!!!!!!!!!!!',
		seePictures: 'Retrouvez les photos en cliquant sur <a>ce lien</a>'
	},
	dashboard: {
		informationBloc: 'Voici quelques informations sur le mariage, n\'hésitez pas à revenir de temps en temps nous ajouterons des informations utiles (logements à proximité par ex.) !'
	},
	location: {
		title: 'Lieu de l\'evenement',
		message: 'Si vous avez oublié où ça se passe, nous célèbrerons l\'évènement au Château de La Fontaine, que vous trouverez au <em>1379 Rue de la Reine Blanche, 45160 Olivet</em> ! {br} En cliquant sur l\'image ci-dessous, vous serez redirigé(e) sur Google Maps avec un itinéraire pour nous y retrouver !'
	},
	participation: {
		title: 'Serez-vous parmi nous ?',
		message: 'Nous avons essayé de grouper au maximum les invités par famille, afin qu\'une personne puisse répondre pour le groupe dans lequel nous l\'avons associé ! Si vous constatez une erreur, n\'hésitez pas à contacter Mathias par <a>mail</a> ou sur mja.arredondo@gmail.com',
		group: 'Groupe',
		save: 'Enregistrer',
		submitted: 'Votre réponse ainsi que celle de votre groupe a bien été enregistrée !',
		members: 'Membre(s) du groupe',
		presentCocktail: 'Présent({suffix}) au cocktail',
		presentDinner: 'Présent({suffix}) au dîner',
		isVegetarian: "Végétarien ?",
		wishList: {
			title: 'Pas de liste de mariage prévue. En revanche, vous pouvez participer à faire de notre lune de miel un voyage inoubliable qui restera gravé dans nos coeurs. Une urne sera prévue le jour J.'
		},
		errors: {
			unauthorized: 'Vous n\'êtes pas connecté(e)',
			noEdit: 'L\'utilisateur que vous souhaitez modifier n\'est pas dans votre groupe.',
			submitted: 'La participation de votre groupe a bien été enregistrée',
			empty: 'Aucun invité n\'est à modifier',
			incorrectData: 'Les données envoyées sont incorrectes'
		}
	},
	pictures: {
		title: 'Les points sont les îles que l\'on a visité {br} Cliquez dessus pour partager avec nous nos souvenirs !',
		subtitle: 'Nous n\'aurions jamais pu faire notre voyage de noces sans vous, merci encore à tous pour votre participation !!',
		tahiti: 'Tahiti',
		tetiaroa: 'Tetiaroa',
		bora: 'Bora-Bora',
		rangiroa: 'Rangiroa',
		tikehau: 'Tikehau',
		moorea: 'Moorea'
	}
}

export default flatten<typeof target, any>(target)
