import React, {useCallback, useContext, useEffect} from 'react'
import '../App.css'
import SnackBar from '../components/snackBar/SnackBar'
import TopAlert from '../components/topAlert/TopAlert'
import Root from './Root'
import Loader from '../components/loader/Loader'
import {useDispatch, useSelector} from 'react-redux'
import {RootState} from '../index'
import {useHistory, useLocation} from 'react-router-dom'
import {displaySnackbar} from '../components/snackBar/reducer/actions'
import {useIntl} from 'react-intl'
import links from '../enums/linksEnum'
import localStorageEnum from '../enums/localStorageEnum'
import {checkToken} from '../api/checkToken'
import errorCatcher from '../utils/errorCatcher'
import {CheckTokenResponseModel} from '../types/user/userModel'
import {saveUser} from '../reducers/user/actions'
import {clearStorage} from '../api/logout'
import {makeStyles, Theme} from '@material-ui/core/styles'
import {ResponsiveContext} from '../utils/context/ResponsiveContext'
import classNames from "classnames";

const useStyles = makeStyles<Theme, { isLargeScreen: boolean }>(
    (theme) => (
        {
            appContainer: {
                margin: props => props.isLargeScreen ? '25px 0 0' : '25px 10px',

            },
            loaderContainer: {
                height: '100vh',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }
        }
    )
)

const USER_UNAUTHORIZED = 'user_unauthorized'
const ERROR_CHECK_TOKEN = 'error_checkToken'

const App = () => {
    const {isLargeScreen} = useContext(ResponsiveContext)
    const classes = useStyles({isLargeScreen})
    const intl = useIntl()
    const history = useHistory()
    const location = useLocation()
    const user = useSelector((state: RootState) => state.user)
    const dispatch = useDispatch()

    const [loading, setLoading] = React.useState<boolean>(true)
    const [isTopAlertOpen, setIsTopAlertOpen] = React.useState<boolean>(true)

    const logout = useCallback(
        () => {
            dispatch(
                displaySnackbar(
                    {
                        open: true,
                        message: intl.formatMessage(
                            {
                                id: 'user.unauthorized',
                                defaultMessage: 'Vous n\'êtes pas connecté',
                                description: 'User not logged message'
                            }
                        ),
                        id: USER_UNAUTHORIZED,
                        hideIcon: true
                    }
                )
            )

            clearStorage()
            history.push(links.login)
        }, [dispatch, history, intl]
    )

    useEffect(
        () => {
            if (user.token.length === 0 && location.pathname !== links.login) {
                const tokenFromStorage = localStorage.getItem(localStorageEnum.token)

                if (tokenFromStorage) {
                    checkToken()
                        .then(
                            (response: any) => {
                                const data: CheckTokenResponseModel = response

                                if (data.isValid) {
                                    dispatch(
                                        saveUser(
                                            {
                                                firstname: data.firstname,
                                                lastname: data.lastname,
                                                token: tokenFromStorage
                                            }
                                        )
                                    )
                                } else {
                                    logout()
                                }
                            }
                        )
                        .catch(
                            (error) => dispatch(
                                errorCatcher(error, ERROR_CHECK_TOKEN)
                            )
                        )
                        .finally(
                            () => setLoading(false)
                        )
                } else {
                    logout()
                }
            } else {
                setLoading(false)
            }
        }, [location.pathname, dispatch, user, logout]
    )

    if (loading) {
        return (
            <div className={classes.loaderContainer}>
                <Loader/>
            </div>
        )
    }

    return (
        <div
            id="outer-container"
        >
            {
                location.pathname !== links.login && (
                    <header className="App-header">
                        <TopAlert
                            isTopAlertOpen={isTopAlertOpen}
                            onTopAlertClosed={() => setIsTopAlertOpen(false)}
                        />
                    </header>
                )
            }

            <div
                id="App"
                className={
                    classNames({
                        [classes.appContainer]: location.pathname !== links.login
                    })
                }
            >
                <Root/>
            </div>

            <SnackBar/>
        </div>
    )
}

export default App
