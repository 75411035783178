import React from 'react'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import MuiDialog from '@material-ui/core/Dialog'
import { makeStyles } from '@material-ui/core/styles'
import styles from '../../styles'

const useStyle = makeStyles((theme) => ({
	...styles(theme),
	dialogRootStyle: {
		padding: '8px 24px',
		'&:first-child': {
			paddingTop: 20
		}
	},
	noPaddingDialogRootStyle: {
		padding: 0,
		'&:first-child': {
			paddingTop: 0
		}
	},
	backDrop: {
		background: 'rgba(0, 0, 0, 0.5)'
	}
}))

interface AcceptingProps {
	onCloseDialog: () => void,
	open: boolean,
	children: React.ReactChild,
	title?: string,
	fullScreen?: boolean,
	maxWidth?: false | 'sm' | 'xs' | 'md' | 'lg' | 'xl' | undefined,
	withPadding?: boolean
}

type DialogProps = AcceptingProps

const Dialog: React.FC<DialogProps> = (
	{
		onCloseDialog,
		open,
		children,
		title,
		fullScreen = true,
		maxWidth = 'sm',
		withPadding = true
	}
) => {
	const classes = useStyle()

	const handleClose = () => {
		onCloseDialog()
	}
	return (
		<MuiDialog
			open={open}
			onClose={handleClose}
			fullScreen={fullScreen}
			maxWidth={maxWidth}
			BackdropProps={{
				classes: {
					root: classes.backDrop
				}
			}}
		>
			{
				title &&
				<DialogTitle>
					{title}
				</DialogTitle>
			}
			<DialogContent
				classes={{
					root: withPadding ? classes.dialogRootStyle : classes.noPaddingDialogRootStyle
				}}
			>
				{children}
			</DialogContent>
		</MuiDialog>
	)
}

export default Dialog
