import React from 'react'
import {FormattedMessage} from 'react-intl'

const WishList = () => (
    <div className="col-xs-12">
        <p>
            <FormattedMessage
                id="participation.wishList.title"
                defaultMessage="Pas de liste de mariage prévue. En revanche, vous pouvez participer à faire de notre lune de miel un voyage inoubliable qui restera gravé dans nos coeurs. Une urne sera prévue le jour J."
                description="Wish list text"
            />
        </p>
    </div>
)

export default WishList
