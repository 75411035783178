import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './containers/App'
import * as serviceWorker from './serviceWorker'
import {createBrowserHistory} from 'history'
import {applyMiddleware, combineReducers, compose, createStore} from 'redux'
import thunk from 'redux-thunk'
import {ConnectedRouter, connectRouter, routerMiddleware} from 'connected-react-router'
import {intlReducer, Provider} from 'react-intl-redux'
import {MuiThemeProvider} from '@material-ui/core/styles'
import theme from './theme'
import './font/montserrat/montserrat.css'
import './font/blackerdisplay/blackerdisplay.css'
import './font/adam-script/adam-script.css'
import './font/bodoniflf/bodoniFLF.css'
import reducers from './config/reducers'
import {reducer as formReducer} from 'redux-form'
import LangProvider from './components/langContext/langContext'
import ResponsiveProvider from './utils/context/ResponsiveContext'
import AppProvider from './utils/context/AppContext'

const history = createBrowserHistory({
    basename: process.env.PUBLIC_URL
})

const rootReducer = combineReducers({
    ...reducers,
    router: connectRouter(history),
    intl: intlReducer,
    form: formReducer
})

export type RootState = ReturnType<typeof rootReducer>

const reactInit = () => {

    // Initialisation de tous les enhancers utilisés avec Redux
    const enhancers = [
        applyMiddleware(
            thunk,
            routerMiddleware(history)
        )
    ]

    // En mode dév, on utilise le devtools
    // @ts-ignore
    if (process.env.NODE_ENV !== 'production' && window.__REDUX_DEVTOOLS_EXTENSION__) {
        //@ts-ignore
        enhancers.push(window.__REDUX_DEVTOOLS_EXTENSION__())
    }

    // Création du store de redux
    let store = createStore(
        rootReducer,
        compose(...enhancers)
    )

    ReactDOM.render(
        <React.StrictMode>
            <Provider store={store}>
                <ConnectedRouter history={history}>
                    <MuiThemeProvider theme={theme}>
                        <LangProvider>
                            <ResponsiveProvider>
                                <AppProvider>
                                    <App/>
                                </AppProvider>
                            </ResponsiveProvider>
                        </LangProvider>
                    </MuiThemeProvider>
                </ConnectedRouter>
            </Provider>

        </React.StrictMode>,
        document.getElementById('root')
    )
}

reactInit()

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
