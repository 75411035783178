import {islandsEnum} from '../enums/islandsEnum'

export interface PicturesModel {
    url: string
    name: string
}

export type IslandsPicturesModel = { [key in islandsEnum]: PicturesModel[] }

export const INITIAL_ISLANDS_PICTURES: IslandsPicturesModel = {
    [islandsEnum.tahiti]: [],
    [islandsEnum.tetiaroa]: [],
    [islandsEnum.bora]: [],
    [islandsEnum.rangiroa]: [],
    [islandsEnum.tikehau]: [],
    [islandsEnum.moorea]: []
}
