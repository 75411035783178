import React, {ReactChild, useState} from 'react'
import {ParticipantsModel} from '../../modules/dashboard/types/participantsModel'

const guests: ParticipantsModel[] = []

export const AppContext = React.createContext(
    {
        guests,
        setGuests: (guests: ParticipantsModel[]) => {}
    }
)

export default (props: { children: ReactChild }) => {
    const [guests, setGuests] = useState<ParticipantsModel[]>([])

    return (
        <AppContext.Provider
            value={{
                guests,
                setGuests
            }}
        >
            {props.children}
        </AppContext.Provider>
    )
}
