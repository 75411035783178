import {REMOVE_USER, SAVE_USER} from './actionTypes'
import {UserModel} from '../../types/user/userModel'
import {UserTypes} from './types'

const initialState: UserModel = {
	firstname: '',
	lastname: '',
	token: ''
}

const user = (
	state: UserModel = initialState,
	action: UserTypes
): UserModel => {
	switch (action.type) {
		case SAVE_USER: {
			return 'payload' in action ? action.payload : initialState
		}
		case REMOVE_USER: {
			return initialState
		}
		default:
			return state
	}
}

export default user
