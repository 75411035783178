import React, {memo} from 'react'
import {Redirect, Route, Switch} from 'react-router'
import links from '../enums/linksEnum'
import Dashboard from '../modules/dashboard/Dashboard'
import Login from '../modules/login/Login'
import Pictures from '../modules/pictures/Pictures'

const Root = () => {
    return (
        <>
            <Switch>
                <Route path={links.dashboard} component={Dashboard}/>
                <Route path={links.login} component={Login}/>
                <Route path={links.pictures} component={Pictures}/>
                <Route path="/" exact>
                    <Redirect to={links.dashboard}/>
                </Route>
            </Switch>
        </>
    )
}

export default memo(Root)
