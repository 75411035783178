import localStorageEnum from '../enums/localStorageEnum'

const logout = () => {
	clearStorage()
}

export const clearStorage = () => {
	localStorage.removeItem(localStorageEnum.token)
}

export default logout
