import { ModalModel } from '../types/types'
import { RootState } from '../../../index'

const getLocalState = (state: RootState) => state.modal

export const getModalByType = (state: RootState, modalType: string): ModalModel => (
	getLocalState(state)[modalType]
)

export const isModalVisible = (state: RootState, modalType: string): boolean => (
	getModalByType(state, modalType) && getModalByType(state, modalType).display
)

export const getModalContent = (state: RootState, modalType: string): any => (
	getModalByType(state, modalType) && getModalByType(state, modalType).content
)
