import React, {useContext, useEffect, useState} from 'react'
import arrayMutators from 'final-form-arrays'
import {Field, Form} from 'react-final-form'
import {FormattedMessage, useIntl} from 'react-intl'
import {FormattedMessageModel} from '../../components/fields/types/fieldsModel'
import {LoginModel} from './types/loginModel'
import {makeStyles, Theme} from '@material-ui/core/styles'
import FormTextField from '../../components/fields/FormTextField'
import classNames from 'classnames'
import {ClassValue} from 'classnames/types'
import Button from '../../components/button/Button'
import Loader from '../../components/loader/Loader'
import fetchFactory from '../../utils/fetchFactory'
import {configEnum} from '../../enums/fetchFactoryEnum'
import {useDispatch} from 'react-redux'
import styles from '../../styles'
import {displaySnackbar} from '../../components/snackBar/reducer/actions'
import localStorageEnum from '../../enums/localStorageEnum'
import {saveUser} from '../../reducers/user/actions'
import {useHistory} from 'react-router-dom'
import links from '../../enums/linksEnum'
import PopinAnnounce from './components/PopinAnnounce'
import {displayModal} from '../../components/dialog/reducer/actions'
import {modalTypes} from '../../components/dialog/enum/modalTypes'
import {ResponsiveContext} from "../../utils/context/ResponsiveContext";

const useStyles = makeStyles<Theme, { isLargeScreen: boolean }>(
    (theme) => (
        {
            loginContainerStyle: {
                backgroundImage: 'url("img/login/global_bg.png")',
                backgroundSize: 'cover',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh',
                position: 'relative',
                overflowX: 'hidden',
                overflowY: 'hidden'
            },
            flowerTopLeft: {
                position: 'absolute',
                top: 25,
                left: props => props.isLargeScreen ? 15 : -30,
                width: 200,

                '& img': {
                    width: '100%'
                }
            },
            flowerTopRight: {
                position: 'absolute',
                transform: 'rotate(90deg)',
                top: 25,
                right: props => props.isLargeScreen ? 15 : -30,
                width: 200,

                '& img': {
                    width: '100%'
                }
            },
            flowerBottomLeft: {
                position: 'absolute',
                transform: 'rotate(270deg)',
                bottom: 25,
                left: props => props.isLargeScreen ? 15 : -30,
                width: 200,

                '& img': {
                    width: '100%'
                }
            },
            flowerBottomRight: {
                position: 'absolute',
                transform: 'rotate(180deg)',
                bottom: 25,
                right: props => props.isLargeScreen ? 15 : -30,
                width: 200,

                '& img': {
                    width: '100%'
                }
            },
            formContainer: {
                minWidth: 250,
                width: '40%',
                margin: 'auto',
                background: theme.palette.white.main,
                padding: 30,

                '& h1': {
                    color: theme.palette.gray.main,
                    margin: 0,
                    marginBottom: 20,
                    textAlign: 'center',
                    ...styles(theme).title2
                }
            },
            formStyle: {
                marginBottom: 20
            },
            buttonContainerStyle: {
                marginTop: 70
            },
            spinnerStyle: {
                textAlign: 'center'
            },
            smallText: {
                fontSize: 14,
                fontWeight: 600
            }
        }
    )
)

const FIRSTNAME_NAME: string = 'firstname'
const FIRSTNAME_PLACEHOLDER: FormattedMessageModel = {
    id: 'login.firstname',
    defaultMessage: 'Votre prénom',
    description: 'Input placeholder'
}

const LASTNAME_NAME: string = 'lastname'
const LASTNAME_PLACEHOLDER: FormattedMessageModel = {
    id: 'login.lastname',
    defaultMessage: 'Votre nom',
    description: 'Input placeholder'
}

const ERROR_LOGIN = 'error_connection'

const Login = () => {
    const {isLargeScreen} = useContext(ResponsiveContext)
    const classes = useStyles({isLargeScreen})
    const intl = useIntl()
    const history = useHistory()
    const dispatch = useDispatch()

    const [loading, setLoading] = useState<boolean>(false)

    const buttonContainerClass: ClassValue = classNames(
        'row',
        classes.buttonContainerStyle
    )

    useEffect(
        () => {
            if (!localStorage.getItem(localStorageEnum.dontShowInformation)) {
                dispatch(displayModal(modalTypes.popinAnnounce))
            }
        }
    )

    const onSubmit = (values: LoginModel) => {
        setLoading(true)

        fetchFactory(
            '/api/login',
            {
                body: JSON.stringify(values),
                method: configEnum.post
            }
        )
            .then(
                (response: any) => {
                    localStorage.setItem(localStorageEnum.token, response)

                    dispatch(
                        saveUser(
                            {
                                firstname: values.firstname,
                                lastname: values.lastname,
                                token: response
                            }
                        )
                    )

                    history.push(links.dashboard)
                }
            )
            .catch(
                (err) => {
                    dispatch(
                        displaySnackbar(
                            {
                                id: ERROR_LOGIN,
                                message: intl.formatMessage(
                                    {
                                        id: err,
                                        defaultMessage: 'Utilisateur inconnu, vous ne pouvez pas accéder au site',
                                        description: 'Error message'
                                    }
                                ),
                                open: true,
                                hideIcon: true
                            }
                        )
                    )
                }
            )
            .finally(
                () => {
                    setLoading(false)
                }
            )
    }

    const validate = (values: LoginModel) => {
        const errors: any = {}

        if (!values.firstname) {
            errors.firstname = intl.formatMessage(
                {
                    id: 'global.errors.required',
                    defaultMessage: 'Requis*',
                    description: 'Required message'
                }
            )
        }
        if (!values.lastname) {
            errors.lastname = intl.formatMessage(
                {
                    id: 'global.errors.required',
                    defaultMessage: 'Requis*',
                    description: 'Required message'
                }
            )
        }

        return errors
    }

    return (
        <div className={classes.loginContainerStyle}>
            <div className={classes.flowerTopLeft}>
                <img src="img/login/flower.png" alt="Top left flower"/>
            </div>
            <div className={classes.flowerTopRight}>
                <img src="img/login/flower.png" alt="Top right flower"/>
            </div>
            <div className={classes.flowerBottomLeft}>
                <img src="img/login/flower.png" alt="Bottom left flower"/>
            </div>
            <div className={classes.flowerBottomRight}>
                <img src="img/login/flower.png" alt="Bottom right flower"/>
            </div>

            <div className={classes.formContainer}>
                <h1>Se connecter</h1>

                <Form
                    mutators={{
                        ...arrayMutators
                    }}
                    onSubmit={onSubmit}
                    validate={validate}
                    render={
                        (
                            {handleSubmit}
                        ) => (
                            <form
                                onSubmit={handleSubmit}
                                className={classes.formStyle}
                            >
                                <div className="row">
                                    <div className="col-xs-12">
                                        <Field
                                            name={FIRSTNAME_NAME}
                                            component={FormTextField}
                                            placeholder={FIRSTNAME_PLACEHOLDER}
                                            maxLength={100}
                                        />
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-xs-12">
                                        <Field
                                            name={LASTNAME_NAME}
                                            component={FormTextField}
                                            placeholder={LASTNAME_PLACEHOLDER}
                                            maxLength={100}
                                        />
                                    </div>
                                </div>

                                <div className={buttonContainerClass}>
                                    <div className="col-xs-12">
                                        {
                                            loading ? (
                                                <div className={classes.spinnerStyle}>
                                                    <Loader/>
                                                </div>
                                            ) : (
                                                <Button
                                                    background="backgroundGreen"
                                                    color="colorWhite"
                                                    textStyle="text8"
                                                    type="submit"
                                                    size="bigButton"
                                                >
                                                    <FormattedMessage
                                                        id="login.submit"
                                                        defaultMessage="Se connecter"
                                                        description="Submit message"
                                                    />
                                                </Button>
                                            )
                                        }
                                    </div>
                                </div>
                            </form>
                        )
                    }
                />

                <small className={classes.smallText}>
                    <FormattedMessage
                        id="login.smallText"
                        defaultMessage="Si vous rencontrez des difficultés à vous connecter, veuillez contacter Mathias au 0621600320 ou par <a>mail</a> (mja.arredondo@gmail.com)"
                        description="Troubleshooting indicator"
                        values={{
                            a: (chunk: string) => <a href="mailto:mja.arredondo@gmail.com">{chunk}</a>
                        }}
                    />
                </small>
            </div>

            <PopinAnnounce/>
        </div>
    )
}

export default Login
