import { DISPLAY_MODAL, HIDE_MODAL } from './actionTypes'
import { ModalTypes } from '../types/types'
import { modalTypes } from '../enum/modalTypes'

const modal = (
	state: any =
		Object.values(modalTypes).reduce(
			(acc, current) => (
				{
					...acc,
					[current]: {
						display: false,
						content: {}
					}
				}
			), {}
		)
	,
	action: ModalTypes
) => {
	switch (action.type) {
		case DISPLAY_MODAL: {
			const payload = action.payload

			return {
				...state,
				[payload.modalType]: {
					content: payload.content || {},
					display: true
				}
			}
		}
		case HIDE_MODAL: {
			const payload = action.payload

			return {
				...state,
				[payload.modalType]: {
					content: {},
					display: false
				}
			}
		}
		default: {
			return state
		}
	}
}

export default modal
