import React, {Dispatch, useContext} from 'react'
import {makeStyles} from '@material-ui/core/styles'
import styles from '../../styles'
import {ClassValue} from 'classnames/types'
import classNames from 'classnames'
import {connect} from 'react-redux'
import errorCatcher from '../../utils/errorCatcher'
import CloseIcon from '@material-ui/icons/Close'
import {ResponsiveContext} from '../../utils/context/ResponsiveContext'
import {FormattedMessage} from 'react-intl'
import Link from '../link/Link'
import linksEnum from '../../enums/linksEnum'

const useStyle = makeStyles((theme) => (
    {
        ...styles(theme),
        topAlertStyle: {
            backgroundImage: 'url("img/dashboard/background_countdown.png")',
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            backgroundPositionX: 'center',
            color: theme.palette.white.main,

            '& .container': {
                position: 'relative',
                width: '100%',
                textAlign: 'center',

                '& svg': {
                    position: 'absolute',
                    width: 25,
                    height: 25,
                    background: theme.palette.green.main,
                    padding: 5,
                    borderRadius: '100%',
                    boxSizing: 'border-box',
                    cursor: 'pointer'
                }
            }
        },
        topAlertLargeScreenStyle: {
            height: 250,

            '& .container': {
                maxWidth: 1100,
                padding: '117px 0 0',

                '& svg': {
                    top: 20,
                    right: 50
                }
            }
        },
        topAlertSmallScreenStyle: {
            height: 165,

            '& .container': {
                padding: '67px 0',

                '& div:first-child': {
                    marginRight: 10
                },
                '& svg': {
                    top: 20,
                    right: 15
                }
            }
        },
        countDownNumber: {
            '& span': {
                backgroundColor: 'white',
                color: theme.palette.black.main,
                border: `5px ridge ${theme.palette.green.main}`
            }
        },
        countDownNumberLargeScreenStyle: {
            '&:first-child': {
                marginRight: 5
            },
            '&:nth-child(2), &:nth-child(3)': {
                marginLeft: 5,
                marginRight: 5
            },
            '&:last-child': {
                marginLeft: 5
            },
            '& span': {
                padding: 15
            }
        },
        countDownNumberSmallScreenStyle: {
            '&:first-child': {
                marginRight: 2
            },
            '&:nth-child(2), &:nth-child(3)': {
                marginLeft: 2,
                marginRight: 2
            },
            '&:last-child': {
                marginLeft: 2
            },
            '& span': {
                padding: 5
            }
        },
        linkContainer: {
            display: 'inline-flex',
            whiteSpace: 'break-spaces'
        }
    }
))

interface AcceptingProps {
    isTopAlertOpen: boolean,
    onTopAlertClosed: () => void
}

interface Store {
    errorCatcher: (error: any, id: string) => void
}

type TopAlertProps =
    AcceptingProps
    & Store

const TopAlert: React.FC<TopAlertProps> = (
    {
        isTopAlertOpen,
        onTopAlertClosed
    }
) => {
    const classes = useStyle()
    const {isTabletteScreen} = useContext(ResponsiveContext)

    const topAlertClass: ClassValue = classNames(
        classes.topAlertStyle,
        isTabletteScreen ? classes.topAlertLargeScreenStyle : classes.topAlertSmallScreenStyle
    )

    return (
        <>
            {
                isTopAlertOpen &&
                (
                    <div className={topAlertClass}>
                        <div className={classNames('container', classes.colorBlack)}>
                           <span>
                                <FormattedMessage
                                    id="topAlert.timesUp"
                                    defaultMessage="ON EST MARRIÉÉÉÉÉ !!!!!"
                                    description="Times up message"
                                />
                            </span>

                            {' '}

                            <span className={classes.linkContainer}>
                                <FormattedMessage
                                    id="topAlert.seePictures"
                                    defaultMessage="Retrouvez les photos en cliquant sur <a>ce lien</a>"
                                    description="See pictures link"
                                    values={{
                                        a: (chunk: string) => (
                                            <Link
                                                color="colorGreen"
                                                textStyle="title5"
                                                hasChevron={false}
                                                href={linksEnum.pictures}
                                            >
                                                {chunk}
                                            </Link>
                                        )
                                    }}
                                />
                            </span>

                            <CloseIcon onClick={() => onTopAlertClosed()}/>
                        </div>
                    </div>
                )
            }
        </>
    )
}

const mapDispatchToProps = (dispatch: Dispatch<any>) => (
    {
        errorCatcher: (error: any, id: string) => {
            dispatch(errorCatcher(error, id))
        }
    }
)

export default connect(null, mapDispatchToProps)(TopAlert)
