import React, {useContext, useState} from 'react'
import {makeStyles} from '@material-ui/core/styles'
import {useDispatch, useSelector} from 'react-redux'
import {ClassValue} from 'classnames/types'
import {Grid} from '@material-ui/core'
import classNames from 'classnames'
import {FormattedMessage} from 'react-intl'
import styles from '../../../styles'
import {RootState} from '../../../index'
import {isModalVisible} from '../../../components/dialog/reducer/selector'
import {modalTypes} from '../../../components/dialog/enum/modalTypes'
import {ResponsiveContext} from '../../../utils/context/ResponsiveContext'
import {hideModal} from '../../../components/dialog/reducer/actions'
import Dialog from '../../../components/dialog/Dialog'
import Button from '../../../components/button/Button'
import Switch from '@material-ui/core/Switch'
import localStorageEnum from '../../../enums/localStorageEnum'

const useStyles = makeStyles((theme) => ({
	...styles(theme),
	iconStyle: {
		'&:hover': {
			background: theme.palette.green.main
		},
		padding: 12,
		borderRadius: '100%',
		color: theme.palette.green.main,
		cursor: 'pointer'
	},
	popInContainerStyle: {
		backgroundColor: '#EAEEDB',
		padding: '35px 45px'
	},
	message: {
		marginTop: 25,
		color: '#86A15E'
	},
	labelStyle: {
		fontFamily: 'BodoniFLF',
		fontWeight: 800,
		color: '#86A15E'
	},
	footerContainerStyle: {
		marginTop: 25
	},
	buttonContainerStyle: {
		alignItems: 'center'
	},
	buttonSmallStyle: {
		flexDirection: 'column-reverse',
		alignContent: 'center'
	},
	link: {
		marginTop: 10
	},
	popinTitleStyle: {
		fontFamily: 'AdamScript',
		fontWeight: 500
	},
	messageStyle: {
		fontFamily: 'BodoniFLF',
		fontWeight: 800,
		fontSize: 16
	}
}))

const PopInDepositSent = () => {
	const classes = useStyles()
	const dispatch = useDispatch()
	const [checkbox, setCheckbox] = useState<boolean>(false)
	const announceIsOpen = useSelector((state: RootState) => isModalVisible(state, modalTypes.popinAnnounce))
	const { isLargeScreen } = useContext(ResponsiveContext)

	const popInTitleClass: ClassValue = classNames(
		classes.colorGreen,
		classes.title2,
		classes.textCenter,
		classes.popinTitleStyle
	)

	const buttonContainerClass: ClassValue = classNames(
		classes.buttonContainerStyle,
		{
			[classes.buttonSmallStyle]: !isLargeScreen
		}
	)

	const messageClass: ClassValue = classNames(
		classes.message,
		classes.text8,
		classes.messageStyle
	)

	const onClose = () => {
		if (checkbox) {
			localStorage.setItem(localStorageEnum.dontShowInformation, 'true')
		}

		dispatch(hideModal(modalTypes.popinAnnounce))
	}

	return (
		<Dialog
			onCloseDialog={() => {
			}}
			open={announceIsOpen}
			fullScreen={false}
			withPadding={false}
		>
			<Grid container className={classes.popInContainerStyle}>
				<Grid item container>
					<span className={popInTitleClass}>
						<FormattedMessage
							id="announce.title"
							defaultMessage="Bienvenue sur le site de mariage de Mathilde et Mathias"
							description="Dialog title"
						/>
					</span>
				</Grid>
				<Grid item container className={messageClass}>
					<p>
						<FormattedMessage
							id="announce.firstText"
							defaultMessage="Le site ayant un but simplement administratif, nous n'avons pas voulu compliquer la connexion avec un mot de passe compliqué et la connexion se fait simplement avec le Prénom et le Nom. Nous vous demandons donc, même si vous connaissez le prénom et le nom d'une autre personne (en dehors de votre famille), de ne pas vous connectez sur son compte."
							description="First text of the popin"
						/>
					</p>
					<p>
						<FormattedMessage
							id="announce.secondText"
							defaultMessage="Nous vous faisons confiance et les données ne sont utilisées que pour nous-mêmes afin de faciliter le compte des personnes présentes et d'en informer notre traiteur ! :)"
							description="Second text of the popin"
						/>
					</p>
					<p>
						<FormattedMessage
							id="announce.thirdText"
							defaultMessage="(Nous comptons sur vous pour ne pas vous connecter sur un espace qui n'est pas le vôtre)"
							description="Third text of the popin"
						/>
					</p>
				</Grid>

				<Grid item container alignItems="center">
					<Grid item xs={6} className={classes.labelStyle}>
						<FormattedMessage
							id="announce.dontShowAgain"
							defaultMessage="Ne plus afficher ce message"
							description="Don't show again label"
						/>
					</Grid>

					<Grid item xs={6}>
						<Switch
							checked={checkbox}
							onChange={() => setCheckbox((oldCheckbox) => !oldCheckbox)}
							inputProps={{ 'aria-label': 'Switch to determine if you want to do not want to see the message next time' }}
						/>
					</Grid>
				</Grid>

				<Grid item container justify="flex-end" className={classes.footerContainerStyle}>
					<Grid item container xs={12} md={10} className={buttonContainerClass}>
						<Grid item xs={12} md={6} className={classes.link}>
							<Button
								background="backgroundGreen"
								color="colorWhite"
								textStyle="text8"
								size="mediumButton"
								onClick={onClose}
							>
								<FormattedMessage
									id="announce.understood"
									defaultMessage="Compris !"
									description="Understood button"
								/>
							</Button>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Dialog>
	)
}

export default PopInDepositSent
