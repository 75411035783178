import { DISPLAY_MODAL, HIDE_MODAL } from './actionTypes'
import { ModalTypes } from '../types/types'

export const displayModal = (modalType: string, content?: any): ModalTypes => {
	return {
		type: DISPLAY_MODAL,
		payload: {
			modalType,
			content
		}
	}
}

export const hideModal = (modalType: string): ModalTypes => {
	return {
		type: HIDE_MODAL,
		payload: {
			modalType
		}
	}
}
