import React, {useContext, useState} from 'react'
import {ParticipantsModel} from '../types/participantsModel'
import Switch from '@material-ui/core/Switch'
import {makeStyles} from '@material-ui/core/styles'
import styles from '../../../styles'
import Button from '../../../components/button/Button'
import {FormattedMessage, useIntl} from 'react-intl'
import Divider from '@material-ui/core/Divider'
import fetchFactory from '../../../utils/fetchFactory'
import {configEnum} from '../../../enums/fetchFactoryEnum'
import {displaySnackbar} from '../../../components/snackBar/reducer/actions'
import links from '../../../enums/linksEnum'
import {useDispatch} from 'react-redux'
import {UNAUTHORIZED_RESPONSE} from '../utils/commonConst'
import {useHistory} from 'react-router'
import Loader from '../../../components/loader/Loader'
import {ResponsiveContext} from '../../../utils/context/ResponsiveContext'
import {AccordionDetails} from '@material-ui/core'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Accordion from '@material-ui/core/Accordion'
import {fieldsEnum} from '../enum/fieldsEnum'
import classNames from 'classnames'

const useStyles = makeStyles(
	(theme) => (
		{
			participationFormContainerClass: {
				width: '100%',

				'& > div:first-child': {
					paddingBottom: 15,
					borderBottom: `1px solid ${theme.palette.black.main}`,
					...styles(theme).text11,
					color: theme.palette.black.main
				},
				'& > div:last-child': {
					marginLeft: 'auto',
					marginTop: 20
				}
			},
			loaderContainer: {
				width: 100,
				height: 100
			},
			nameContainer: {
				...styles(theme).text7,
				color: theme.palette.black.main,
				textTransform: 'capitalize'
			},
			accordionDetailsSmallScreen: {
				flexDirection: 'column',

				'& > div:first-child': {
					marginBottom: 10
				}
			},
			participantRow: {
				marginTop: 5,
				marginBottom: 5
			}
		}
	)
)

interface AcceptingProps {
	guests: ParticipantsModel[]
}

const ERROR_SAVE_PARTICIPANTS = 'error_save_participants'
const PARTICIPATION_SUBMITTED = 'participation_submitted'

type participationFormProps = AcceptingProps

const ParticipationForm: React.FC<participationFormProps> = (
	{
		guests
	}
) => {
	const { isLargeScreen } = useContext(ResponsiveContext)
	const classes = useStyles()
	const intl = useIntl()
	const history = useHistory()
	const dispatch = useDispatch()

	const [guestsState, setGuestsState] = useState<ParticipantsModel[]>(guests)
	const [loading, setLoading] = useState<boolean>(false)

	const saveChanges = () => {
		setLoading(true)

		fetchFactory(
			'/api/save/choices',
			{
				method: configEnum.post,
				body: JSON.stringify({ data: guestsState })
			}
		)
			.then(
				(response: any) => dispatch(
					displaySnackbar(
						{
							id: PARTICIPATION_SUBMITTED,
							message: intl.formatMessage(
								{
									id: response,
									defaultMessage: 'Votre réponse ainsi que celle de votre groupe a bien été enregistrée !',
									description: 'Error message'
								}
							),
							open: true,
							hideIcon: true
						}
					)
				)
			)
			.catch(
				(err: any) => {
					dispatch(
						displaySnackbar(
							{
								id: ERROR_SAVE_PARTICIPANTS,
								message: intl.formatMessage(
									{
										id: err,
										defaultMessage: 'Vous n\'êtes pas autorisé à accéder à ce contenu',
										description: 'Error message'
									}
								),
								open: true,
								hideIcon: true
							}
						)
					)

					if (err === UNAUTHORIZED_RESPONSE) {
						history.push(links.login)
					}
				}
			)
			.finally(
				() => setLoading(false)
			)
	}

	const updateGuest = (id: number, type: fieldsEnum) => {
		const index = guestsState.findIndex(
			(guest) => guest.id === id
		)

		if (index >= 0) {
			setGuestsState(
				(oldState) => (
					[
						...oldState.slice(0, index),
						{
							...oldState[index],
							is_present: type !== fieldsEnum.cocktail ? oldState[index].is_present : !oldState[index].is_present,
							is_dinning: type !== fieldsEnum.dinning ? oldState[index].is_dinning : !oldState[index].is_dinning,
							is_vegetarian: type !== fieldsEnum.vegetarian ? oldState[index].is_vegetarian : !oldState[index].is_vegetarian
						},
						...oldState.slice(index + 1)
					]
				)
			)
		}
	}

	if (isLargeScreen) {
		return (
			<AccordionDetails>
				<div className={classes.participationFormContainerClass}>
					<div className="row middle-xs">
						<div className={`col-xs-${guests[0].is_invited ? 3 : 4}`}>
							<FormattedMessage
								id="participation.members"
								defaultMessage="Membre(s) du groupe"
								description="Group members"
							/>
						</div>
						<div className={`col-xs-${guests[0].is_invited ? 3 : 8} center-xs`}>
							<FormattedMessage
								id="participation.presentCocktail"
								defaultMessage="Présent({suffix}) au coktail"
								description="Is present for the cocktail"
								values={{
									suffix: 's'
								}}
							/>
						</div>
						{
							guests[0].is_invited && (
								<>
									<div className="col-xs-3 center-xs">
										<FormattedMessage
											id="participation.presentDinner"
											defaultMessage="Présent({suffix}) au dîner"
											description="Is present for the dinner"
											values={{
												suffix: 's'
											}}
										/>
									</div>

									<div className={`col-xs-3 center-xs`}>
										<FormattedMessage
											id="participation.isVegetarian"
											defaultMessage="Végétarien ?"
											description="Is vegetarian"
										/>
									</div>
								</>
							)
						}
					</div>

					{
						guestsState.map(
							(guest) => (
								<React.Fragment key={guest.id}>
									<div
										className={
											classNames(
												'row middle-xs',
												classes.participantRow
											)
										}
									>
										<div
											className={
												classNames(
													`col-xs-${guests[0].is_invited ? 3 : 4}`,
													classes.nameContainer
												)
											}
										>
											{guest.firstname} {guest.lastname}
										</div>
										<div className={`col-xs-${guests[0].is_invited ? 3 : 8} center-xs`}>
											<Switch
												checked={guest.is_present}
												onChange={() => updateGuest(guest.id, fieldsEnum.cocktail)}
												inputProps={{ 'aria-label': 'Switch to determine your presence for the cocktail' }}
											/>
										</div>

										{
											guest.is_invited && (
												<>
													<div className="col-xs-3 center-xs">
														<Switch
															checked={guest.is_dinning}
															onChange={() => updateGuest(guest.id, fieldsEnum.dinning)}
															inputProps={{ 'aria-label': 'Switch to determine your presence for the dinner' }}
														/>
													</div>

													<div className={`col-xs-3 center-xs`}>
														<Switch
															checked={guest.is_vegetarian}
															onChange={() => updateGuest(guest.id, fieldsEnum.vegetarian)}
															inputProps={{ 'aria-label': 'Switch to determine if you are vegetarian or not' }}
														/>
													</div>
												</>
											)
										}
									</div>

									<Divider />
								</React.Fragment>
							)
						)
					}
					<div className="col-xs-4">
						{
							loading ? (
								<Loader />
							) : (
								<Button
									background="backgroundWhite"
									color="colorBlack"
									textStyle="text8"
									onClick={saveChanges}
								>
									<FormattedMessage
										id="participation.save"
										defaultMessage="Enregistrer"
										description="Save's message"
									/>
								</Button>
							)
						}
					</div>
				</div>
			</AccordionDetails>
		)
	}

	return (
		<>
			{
				guestsState.map(
					(guest) => (
						<Accordion
							defaultExpanded
							key={guest.id}
						>
							<AccordionSummary
								expandIcon={<ExpandMoreIcon />}
								aria-controls="panel1c-content"
								id="panel1c-header"
								className="row"
							>
								<div className="col-xs-12">
									<h1 className={classes.nameContainer}>
										{guest.firstname} {guest.lastname}
									</h1>
								</div>
							</AccordionSummary>

							<AccordionDetails className={classes.accordionDetailsSmallScreen}>
								<div className="row middle-xs">
									<div className="col-xs-6 center-xs">
										<FormattedMessage
											id="participation.presentCocktail"
											defaultMessage="Présent({suffix}) au coktail"
											description="Is present for the cocktail"
											values={{
												suffix: 'e'
											}}
										/>
									</div>
									<div className="col-xs-6 center-xs">
										<Switch
											checked={guest.is_present}
											onChange={() => updateGuest(guest.id, fieldsEnum.cocktail)}
											inputProps={{ 'aria-label': 'Switch to determine your presence for the cocktail' }}
										/>
									</div>
								</div>

								{
									guest.is_invited && (
										<div className="row middle-xs">
											<div className="col-xs-6 center-xs">
												<FormattedMessage
													id="participation.presentDinner"
													defaultMessage="Présent({suffix}) au dîner"
													description="Is present for the dinner"
													values={{
														suffix: 'e'
													}}
												/>
											</div>
											<div className="col-xs-6 center-xs">
												<Switch
													checked={guest.is_dinning}
													onChange={() => updateGuest(guest.id, fieldsEnum.dinning)}
													inputProps={{ 'aria-label': 'Switch to determine your presence for the dinner' }}
												/>
											</div>
										</div>
									)
								}

								<div className="row middle-xs">
									<div className="col-xs-6 center-xs">
										<FormattedMessage
											id="participation.isVegetarian"
											defaultMessage="Végétarien ?"
											description="Is vegetarian"
										/>
									</div>
									<div className="col-xs-6 center-xs">
										<Switch
											checked={guest.is_vegetarian}
											onChange={() => updateGuest(guest.id, fieldsEnum.vegetarian)}
											inputProps={{ 'aria-label': 'Switch to determine if you are vegetarian or not' }}
										/>
									</div>
								</div>
							</AccordionDetails>
						</Accordion>
					)
				)
			}

			<div className="row end-xs">
				<div className="col-xs-4">
					{
						loading ? (
							<Loader size={50} />
						) : (
							<Button
								background="backgroundWhite"
								color="colorBlack"
								textStyle="text8"
								onClick={saveChanges}
							>
								<FormattedMessage
									id="participation.save"
									defaultMessage="Enregistrer"
									description="Save's message"
								/>
							</Button>
						)
					}
				</div>
			</div>
		</>
	)
}

export default ParticipationForm
