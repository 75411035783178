import React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import makeStyles from '@material-ui/core/styles/makeStyles'
import styles from '../../styles'
import { ClassValue } from 'classnames/types'
import classNames from 'classnames'

const useStyles = makeStyles(
	(theme) => ({
			...styles(theme),
			circularProgressStyle: {
				width: '100%',
				height: '100%',
				marginBottom: 20,
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',

				'& div': {
					width: '100px !important',
					height: '100px !important'
				}
			},
			circularWithSize: {
				display: 'flex',
				justifyContent: 'flex-end',
				marginRight: 5
			}
		}
	)
)

interface AcceptingProps {
	size?: number | string
}

const Loader: React.FC<AcceptingProps> = (
	{
		size
	}
) => {
	const classes = useStyles()

	const circularProgressClass: ClassValue = classNames(
		size ? classes.circularWithSize : classes.circularProgressStyle
	)

	return (
		<div className={circularProgressClass}>
			<CircularProgress size={size} />
		</div>
	)
}

export default Loader
