import flatten from 'flat'

const target = {
    global: {
        errors: {
            required: 'Required*'
        }
    },
    user: {
        unauthorized: 'You are not logged in'
    },
    announce: {
        title: 'Welcome on Mathilde and Mathias\' wedding website.',
        firstText: 'You will find there all the informations you need related to our wedding.',
        secondText: 'It also helps us to know if will be part of this special day. To get in it, nothing easier : enter you name and first name.',
        thirdText: '(We rely on you for not trying to connect on someone\'s account)',
        dontShowAgain: 'Don\'t show this message again',
        understood: 'Understood !'
    },
    login: {
        firstname: 'Your firstname',
        lastname: 'Your lastname',
        submit: 'Login',
        errors: {
            credentials: {
                notFound: 'User not found, you can\'t access to the site',
                empty: 'Please, make sure to fill your firstname and your lastname'
            }
        },
        smallText: 'If you are having trouble logging in, please contact Mathias on 0621600320 or by <a>mail</a> (mja.arredondo@gmail.com)'
    },
    topAlert: {
        timesUp: 'WE ARE MARRIED!!!!!!!!!!!!',
        seePictures: 'Find the pictures by clicking on <a>this link</a>'
    },
    dashboard: {
        informationBloc: 'Here is some information on the wedding, do not hesitate to come back from time to time we will add useful information (nearby accommodation for example)!'
    },
    location: {
        title: 'Event\'s location',
        message: 'If you forgot where it\'s going, we\'ll be celebrating the event at Château de La Fontaine, which you will find at <em>1379 Rue de la Reine Blanche, 45160 Olivet</em>! {br} By clicking on the picture below, you will be redirected to Google Maps with a route to find us there!'
    },
    participation: {
        title: 'Would you be with us?',
        message: 'We have tried to group the guests as much as possible by family, so that one person can answer for the group in which we have associated them! If you notice an error, do not hesitate to contact Mathias by <a>mail</a> or on mja.arredondo@gmail.com',
        group: 'Group',
        save: 'Save',
        submitted: 'Your response and the one of your group has been saved!',
        members: 'Group members',
        presentCocktail: 'Present for the cocktail',
        presentDinner: 'Present for the dinner',
        isVegetarian: 'Vegetarian ?',
        wishList: {
            title: 'No wedding list intended. However, you can make a contribution for our honeymoon to be an unforgettable trip that will stay forever in our hearts. A box will be available during the D-Day.'
        },
        errors: {
            unauthorized: 'You are not logged in',
            noEdit: 'The user you want to edit is not in your group.',
            submitted: 'The participation of your group has been saved',
            empty: 'There is not guest to edit',
            incorrectData: 'The data you are sending are incorrects'
        }
    },
    pictures: {
        title: 'The dots are the islands we visited {br} Click on them to share our memories with us!',
        subtitle: 'We would never have been able to make our honeymoon without you, thank you all again for your participation!!',
        tahiti: 'Tahiti',
        tetiaroa: 'Tetiaroa',
        bora: 'Bora-Bora',
        rangiroa: 'Rangiroa',
        tikehau: 'Tikehau',
        moorea: 'Moorea'
    }
}

export default flatten<typeof target, any>(target)
