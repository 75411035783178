import React from 'react'
import {FormattedMessage} from 'react-intl'
import {makeStyles} from '@material-ui/core/styles'

const useStyles = makeStyles(
    () => (
        {
            imgStyle: {
                cursor: 'pointer'
            }
        }
    )
)

const Location = () => {
    const classes = useStyles()

    const onMapClicked = () => {
        window.open('https://www.google.com/maps/dir//Ch%C3%A2teau+de+La+Fontaine,+1379+Rue+de+la+Reine+Blanche,+45160+Olivet/@47.872244,1.8778932,17z/data=!4m16!1m6!3m5!1s0x47e4e50c0f189999:0xba7da55aad41394a!2sCh%C3%A2teau+de+La+Fontaine!8m2!3d47.8722373!4d1.8802618!4m8!1m0!1m5!1m1!1s0x47e4e50c0f189999:0xba7da55aad41394a!2m2!1d1.8802618!2d47.8722373!3e3', '_blank')
    }

    return (
        <>
            <h1>
                <FormattedMessage
                    id="location.title"
                    defaultMessage="Lieu de l'évènement"
                    description="Location title"
                />
            </h1>

            <p>
                <FormattedMessage
                    id="location.message"
                    defaultMessage="Si vous avez oublié où ça se passe,"
                    description="Location message"
                    values={
                        {
                            em: (...chunks: string[]) => <em>{chunks}</em>,
                            br: <br/>
                        }
                    }
                />
            </p>

            <img
                src="/img/dashboard/location/castle.jpg"
                alt="Champvallins' castle"
                width="100%"
                onClick={onMapClicked}
                className={classes.imgStyle}
            />
        </>
    )
}

export default Location
