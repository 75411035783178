const styles = (theme: any) => {
	return (
		{
			backgroundBlack: {
				backgroundColor: `${theme.palette.black.main} !important`
			},
			backgroundGray: {
				backgroundColor: `${theme.palette.gray.main} !important`
			},
			backgroundGreen: {
				backgroundColor: `${theme.palette.green.main} !important`
			},
			backgroundWhite: {
				backgroundColor: `${theme.palette.white.main} !important`
			},
			backgroundInherit: {
				backgroundColor: 'inherit !important'
			},
			borderBlack: {
				border: `1px solid ${theme.palette.black.main}`
			},
			borderGray: {
				border: `1px solid ${theme.palette.gray.main}`
			},
			borderGreen: {
				border: `1px solid ${theme.palette.green.main}`
			},
			borderWhite: {
				border: `1px solid ${theme.palette.white.main}`
			},
			title0: theme.typography.title0,
			title1: theme.typography.title1,
			title2: theme.typography.title2,
			title3: theme.typography.title3,
			title4: theme.typography.title4,
			title5: theme.typography.title5,
			title6: theme.typography.title6,
			title7: theme.typography.title7,
			title8: theme.typography.title8,
			text0: theme.typography.text0,
			text0_opacity: theme.typography.text0_opacity,
			text1_opacity: theme.typography.text1_opacity,
			text1: theme.typography.text1,
			text2_opacity: theme.typography.text2_opacity,
			text2: theme.typography.text2,
			text3_opacity: theme.typography.text3_opacity,
			text3: theme.typography.text3,
			text4_opacity: theme.typography.text4_opacity,
			text4: theme.typography.text4,
			text5: theme.typography.text5,
			text6: theme.typography.text6,
			text6_opacity: theme.typography.text6_opacity,
			text7: theme.typography.text7,
			text8: theme.typography.text8,
			text9: theme.typography.text9,
			text9_opacity: theme.typography.text9_opacity,
			text10: theme.typography.text10,
			text11: theme.typography.text11,
			text11_opacity: theme.typography.text11_opacity,
			text12: theme.typography.text12,
			text13: theme.typography.text13,
			text13_opacity: theme.typography.text13_opacity,
			text14: theme.typography.text14,
			text15: theme.typography.text15,
			text16_opacity: theme.typography.text16_opacity,
			text17: theme.typography.text17,
			colorBlack: {
				color: theme.palette.black.main
			},
			colorGray: {
				color: theme.palette.gray.main
			},
			colorGreen: {
				color: theme.palette.green.main
			},
			colorWhite: {
				color: theme.palette.white.main
			},
			cursorPointer: {
				cursor: 'pointer'
			},
			textCenter: {
				textAlign: 'center' as 'center'
			}

		}
	)
}

export default styles
