import {REMOVE_USER, SAVE_USER} from './actionTypes'
import {UserModel} from '../../types/user/userModel'
import {UserTypes} from './types'

export const saveUser = (user: UserModel): UserTypes => {
	return ({
		type: SAVE_USER,
		payload: user
	})
}

export const removeUser = (): UserTypes => {
	return ({
		type: REMOVE_USER
	})
}
